export default [
  {
    img: "/img/theme/bootstrap.jpg",
    title: "Argon Design System",
    budget: "$2500 USD",
    status: "pending",
    statusType: "warning",
    completion: 60,
  },
  {
    img: "/img/theme/angular.jpg",
    title: "Angular Now UI Kit PRO",
    budget: "$1800 USD",
    status: "completed",
    statusType: "success",
    completion: 100,
  },
  {
    img: "/img/theme/sketch.jpg",
    title: "Black Dashboard",
    budget: "$3150 USD",
    status: "delayed",
    statusType: "danger",
    completion: 72,
  },
  {
    img: "/img/theme/react.jpg",
    title: "React Material Dashboard",
    budget: "$4400 USD",
    status: "on schedule",
    statusType: "info",
    completion: 90,
  },
  {
    img: "/img/theme/vue.jpg",
    title: "Vue Paper UI Kit PRO",
    budget: "$2200 USD",
    status: "completed",
    statusType: "success",
    completion: 100,
  },
  {
    img: "/img/theme/bootstrap.jpg",
    title: "Argon Design System",
    budget: "$2500 USD",
    status: "pending",
    statusType: "warning",
    completion: 60,
  },
  {
    img: "/img/theme/angular.jpg",
    title: "Angular Now UI Kit PRO",
    budget: "$1800 USD",
    status: "completed",
    statusType: "success",
    completion: 100,
  },
  {
    img: "/img/theme/sketch.jpg",
    title: "Black Dashboard",
    budget: "$3150 USD",
    status: "delayed",
    statusType: "danger",
    completion: 72,
  },
  {
    img: "/img/theme/vue.jpg",
    title: "Vue Paper UI Kit PRO",
    budget: "$2200 USD",
    status: "completed",
    statusType: "success",
    completion: 100,
  },
];
